// @import "./../bootstrap/variables";

@import "./vendors/timezonePicker";
@import "./vendors/date-timepicker";

@import "variables";
@import "./components/forms";
@import "./components/modal";

.welcome-tour .swiper-pagination-bullets {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1000000;
  span {
    width: 12px;
    height: 12px;
  }
}
