@import "./theme/app/variables.scss";

.introjs-tooltipbuttons {
  a {
    background-color: $main-clr;
    background-image: none;
    border-color: $main-clr;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    text-shadow: none;
    transition: all $main-btn-transition-duration
      $main-btn-transition-timing-function;

    &:focus,
    &:hover {
      background-color: #033670;
      background-image: none;
      border-color: #033670;
      box-shadow: none;
      color: #fff;
      cursor: pointer;
      text-decoration: none;
      text-shadow: none;
    }
  }
}

.introjs-button.introjs-disabled {
  background-color: #8faaf5;
  background-image: none;
  border-color: #8faaf5;
  box-shadow: none;
  color: #fff;
  cursor: not-allowed;
  text-decoration: none;
  text-shadow: none;

  &:focus,
  &:hover {
    background-color: #8faaf5;
    background-image: none;
    border-color: #8faaf5;
    box-shadow: none;
    color: #fff;
    cursor: not-allowed;
    text-decoration: none;
    text-shadow: none;
  }
}

.introjs-tooltip-header {
  .introjs-skipbutton {
    background-color: transparent;
    background-image: none;
    border: none;
    box-shadow: none;
    color: $main-clr;
    cursor: pointer;
    text-decoration: none;
    text-shadow: none;
    transition: all $main-btn-transition-duration
      $main-btn-transition-timing-function;
    font-size: 14px;
    top: 5px;
    right: 5px;
    line-height: unset;
    padding: 4px 6px;
    height: unset;

    &:focus,
    &:hover {
      background-color: transparent;
      background-image: none;
      box-shadow: none;
      color: #fff;
      cursor: pointer;
      text-decoration: none;
      text-shadow: none;
    }
  }
}

.introjs-progressbar {
  background-color: $main-clr;
}

.introjs-tooltiptext {
  color: $main-font-clr;
}

.introjs-dontShowAgain {
  display: flex;
  align-items: center;
  gap: 6px;

  #introjs-dontShowAgain {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 0;
  }

  label {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: $dark-grey-font-clr;
    cursor: pointer;
  }
}
