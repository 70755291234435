input.form-control,
select.form-control,
textarea.form-control {
  background: $main-input-background-clr;
  border-color: $main-input-border-clr;
  color: $main-input-clr;
  height: 48px;
  border-radius: $main-input-border-radius;
  font-size: $main-input-font-size;
  font-weight: $main-input-font-weight;
  padding: $main-input-padding;
  transition: $main-input-transition-duration
    $main-input-transition-timing-function;

  &:hover {
    border-color: $main-input-border-clr-hvr;
  }

  &:focus {
    border-color: $main-input-border-clr-focus;
    color: $main-input-clr-focus;
    background: $main-input-background-clr-focus;
  }

  &.error {
    border-color: $main-input-border-clr-error;
    background: $main-input-bg-clr-error;
  }
}

textarea.form-control {
  height: 80px;
}

.form-control-feedback {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.input-group {
  width: 100%;
}

// .input-group-addon {
//   border-color: $agio-blue-grey-dark !important; // !imortent => new
//   background: $agio-grey-lightest !important; // !imortent => new
//   color: $agio-grey-darkest !important; // !imortent => new
// }

.form-block-row {
  margin-bottom: 40px;
  .addon-button {
    top: 25px;
    bottom: auto;
  }
}

.form-row {
  position: relative;

  > i.fa-spin {
    position: absolute;
    right: -35px;
    top: 8px;
    font-size: 20px;
    color: $main-clr;
  }

  .addon-button {
    position: absolute;
    right: -45px;
    bottom: 2.5px;
    font-size: 13px;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #fff;
    background: #1f2f3f;
    &.circle {
      border-radius: 50%;
      &.circle-red {
        background: #cc3e4a;
      }
    }
  }
}

.advanced-button {
  margin: 13px;
  margin-top: 28.5px;
  position: relative;
  &.no-label {
    margin-top: 13px;
  }
}

.toggle-row {
  margin: 10px 0;
  h4 {
    margin: 0;
    font-weight: 700;
  }
}

.has-feedback .form-control {
  padding-right: 0 !important;
}
.input-group-btn {
  button {
    height: 40px;
  }
}

.form-margin {
  margin-bottom: 15px;
}

.help-icon {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 10px;
  line-height: 20px !important;
  color: $main-clr;
  text-align: center;
  cursor: pointer;
}
label {
  position: relative;
}
