:global {
  @import "~normalize.css/normalize";
  @import "plugins/notifications";
  @import "plugins/recharts";
}

@import "theme/app/variables";

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 1em;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.app-panel-container {
  background-color: #f9fafc;
  padding: 25px;
  // overflow-y: auto;
  min-height: 100%;
}

// global styles for input
input,
textarea {
  padding: 10px;
  border: 1px solid $main-input-border-clr;
  border-radius: $main-input-border-radius;
  color: $main-input-clr;
  outline: none;
  font-size: $main-input-font-size;
  background-color: $main-input-background-clr;
}

input:focus,
input:active,
textarea:focus,
textarea:active {
  border: 1px solid $main-input-border-clr-focus;
  background-color: $main-input-background-clr-focus;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

.onboarding {
  font-family: "Lato";

  .ant-progress-text {
    background: #fff;
    box-shadow: 0 20px 50px rgba(49, 49, 49, 0.15);
    border-radius: 50%;
    width: 42.59px;
    padding: 0em;
    height: 42.35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $main-input-clr-focus;
    font-size: 14px;
    font-weight: 600;
  }
  svg.ant-progress-circle::after {
    content: "";
    position: absolute;
    width: 3.3px;
    height: 3.3px;
    border-radius: 50%;
    background-color: #fff;
  }
}

.uploadFile {
  > button {
    color: white !important;
    > span {
      color: white !important;
    }
  }
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border: none;
  color: #024da1;
  background-color: inherit;
  &:after {
    content: "";
    color: #024da1;
    width: 100%;
    position: absolute;
    bottom: -1px;
    height: 3px;
    display: block;
    background: #024da1;
    border-radius: 100px;
  }
}
.pull-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-footer .btn + .btn {
  color: #fff;
  font-weight: bold !important;
}

.btn-default {
  color: #000 !important;
}

.btn {
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.btn-primary,
.btn-danger {
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.btn-primary {
  background-color: #024da1;
  border-color: #024da1;
}

.btn-primary:hover:enabled {
  background-color: #033670;
  border-color: #033670;
}

.multiple-value-text-input .multiple-value-text-input-item {
  background: #2c97de !important;
  color: white !important;
}
.Select-control .Select-input {
  border-radius: 5px !important;
  border-color: #1d86f1 !important;
  height: 5rem !important;
  font-size: 1.8rem !important;
}
.Select-value {
  display: flex;
  align-items: center;
}

.media {
  margin-top: 3rem !important;
}

.input-group .form-control {
  z-index: 0;
}

.Select-menu-outer {
  z-index: 999 !important;
}

.sdl-campaign {
  .form-control-feedback.glyphicon.glyphicon-remove {
    display: none;
  }
}

// onboarding screen
.ant-collapse {
  border-radius: 8px !important;
  border: 1px solid transparent !important;
}

.platformIntegration,
.events,
.commChannels,
.retargeting,
.deepLinks {
  font-family: $main-dashboard-font;
  margin-bottom: 16px;

  .ant-collapse-header {
    position: relative;
    padding: 24px 64px !important;
    line-height: 22px;
    cursor: pointer;
    transition: all 0.3s;
    background: rgba(2, 77, 161, 0.1) !important;
    border: 1px solid #e0e0e0;
    border-radius: 8px 8px 0px 0px !important;
    color: #000 !important;
    font-size: 24px !important;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    padding: 1em 0em;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 3em;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    svg {
      display: none;
    }
    &::after {
      content: "";
      display: block;
      background: url("https://cdn.appgain.io/5ccffb1d48938f000e52c771/bigh/5gDp3.jpg")
        no-repeat;
      width: 36px;
      height: 36px;
      float: left;
      margin: 0 6px 0 0;
    }
  }
}

.addretargeting .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-family: "Lato";

  padding-left: 0;
}

.platform,
.addEvent,
.addcommChannels,
.addretargeting,
.addDeepLinks {
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    display: none;
  }
  .oldtitle {
    display: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: #fff !important;
    border: none !important;
    border-bottom: 1px solid #f3f3f3 !important;
    color: #121212;
    padding-left: 0em;

    .anticon {
      svg {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }

  .ant-collapse {
    border: none !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 16px 52px !important;
  }
  .ant-collapse-content {
    border-top: none !important;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
}

.addcommChannels,
.addretargeting,
.addDeepLinks {
  padding: 24px 32px;
  background: #fff;
  border: 1px solid #b9b9c7;
  border-radius: 8px;
  box-shadow: 0px 22px 42px 0px rgba(240, 238, 250, 0.7);
}

.events {
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    &::after {
      content: "";
      background: url("https://cdn.appgain.io/5ccffb1d48938f000e52c771/bigh/0wUO8.jpg")
        no-repeat;
    }
  }
}

.commChannels {
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    &::after {
      content: "";
      background: url("https://cdn.appgain.io/5ccffb1d48938f000e52c771/bigh/vsQxy.jpg")
        no-repeat;
    }
  }
}
.retargeting {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 3em;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    svg {
      display: none;
    }
    &::after {
      content: "";
      display: block;
      background: url("https://cdn.appgain.io/5ccffb1d48938f000e52c771/bigh/E2gSu.jpg")
        no-repeat;
      width: 36px;
      height: 36px;
      float: left;
      margin: 0 6px 0 0;
    }
  }
}

.deepLinks {
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    &::after {
      content: "";
      background: url("https://cdn.appgain.io/5ccffb1d48938f000e52c771/bigh/F52y8.jpg")
        no-repeat;
    }
  }
}

// automator
#channels-tabs {
  .active {
    border-bottom: 1px solid $main-btn-clr;
    a {
      color: #000;
    }
  }
}
