.timezone-picker-list {
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #e6ebec;
  margin-top: -1px;
  border-radius: 0 0 3px 3px;
  display: none;
}
.timezone-picker-list-rel {
  position: relative;
}
.timezone-picker-list-abs {
  position: absolute;
  background-color: #fff;
  z-index: 2000;
  opacity: 0.99;
}
.timezone-picker-list-item {
  color: #444;
  padding: 5px 12px;
}
.timezone-picker-list-item-active {
  background: #ececec;
  cursor: pointer;
}
.timezone-picker-open .timezone-picker-list {
  display: block;
}
.timezone-picker-selected .timezone-picker-textfield input {
  color: #474747;
}
