// new variables
$main-dashboard-font: "Lato", sans-serif;

// colors
$main-clr: #024da1;
$main-bg-clr: #f5f5f5;
$overlay-bg-clr: #00000040;
$main-grey-clr: #76767640;
$main-delete-clr: #f20000;
$main-delete-clr-hvr: #c00404;
$main-delete-clr-disabled: #f200001a;

// animation
$main-animation-duration: 500ms;
$main-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

// sidebar animation
$main-sidebar-animation-duration: 350ms;
$main-sidebar-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

// sidebar
$main-sidebar-bg-clr: #1f2f3f;
$sidebar-active-tab-bg: #8faaf5;

// font colors
$main-font-clr: #1f2f3f;
$gray-font-clr: #76767680;
$dark-font-clr: #121212;
$light-gray-font-clr: #626262;
$dark-grey-font-clr: #707070;
$helper-text-clr: #707070;
$error-text-clr: #cc3e4a;
$warning-text-clr: #f5a623;
$medium-grey-clr: #9e9d9d;
$dark-medium-font-clr: #292929;
$muted-clr: #7c8c9b;
$media-clr: #616161;

// font sizes
$main-breadcrumb-font-size: 16px;
$main-button-font-size: 16px;
$secondary-button-font-size: 15px;
$main-font-size: 16px;
$secondary-font-size: 15px;

// input colors
$main-input-border-clr: #b9b9c7;
$main-input-border-radius: 4px;
$main-input-clr-focus: $main-clr;
$main-input-clr: #1f2f3f;
$main-input-background-clr-focus: #024da119;
$main-input-background-clr: #fff;
$main-input-border-clr-focus: #024da13f;
$main-input-font-size: 15px;
$main-input-font-weight: 400;
$main-input-border-clr-error: #cc3e4a;
$main-input-bg-clr-error: #cc3e4a1f;
$main-input-transition-duration: 500ms;
$main-input-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
$main-input-padding: 10px;
$main-input-height: 48px;
$main-input-border: 1px solid $main-input-border-clr;
$main-input-border-clr-hvr: #024da13f;
$main-input-placeholder-clr: #9e9d9d;
$main-input-placeholder-font-size: 14px;
$main-input-placeholder-font-weight: 400;

// radio
$main-radio-label-clr: #030d18;

// button colors
$main-btn-clr: $main-clr;
$main-btn-clr-hvr: #033670;
$main-btn-clr-disabled: #8faaf5;
$main-btn-clr-delete: $main-delete-clr;
$main-btn-clr-delete-hvr: $main-delete-clr-hvr;
$main-btn-clr-delete-disabled: $main-delete-clr-disabled;
$main-btn-border-radius: 4px;
$main-btn-clr-border: 1px solid $main-btn-clr;
$main-btn-clr-txt: #fff;
$main-btn-font-size: 16px;
$main-btn-font-weight: 400;
$main-btn-padding: 0 18px;
$main-btn-height: 48px;
$main-btn-transition-duration: 500ms;
$main-btn-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

// table
$border-gray-clr: #dfdfdf;
$th-clr: #828282;
